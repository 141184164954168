import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.css']
})
export class AddFaqComponent implements OnInit {
  addFaqForm: FormGroup
  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    this.addFaqForm = new FormGroup({
      'topic' : new FormControl('',),  
      'question' : new FormControl('',), 
      'answer' : new FormControl('',), 
      
    })
  }
  get topic(): any {
    return this.addFaqForm.get('topic')
}
get question(): any {
  return this.addFaqForm.get('question')
}
get answer(): any {
  return this.addFaqForm.get('answer')
}
  addFaqFunc(){
      let data = {
        'topic': this.addFaqForm.value.topic,
        'answer': this.addFaqForm.value.answer,
        'question': this.addFaqForm.value.question
      }
      var url = "api/v1/faq/faqs";
        this.service.showSpinner();
      this.service.post(url, data).subscribe(res => {
  
         this.service.hideSpinner();
        if (res['responseCode'] == 200) {
          this.toastr.success('FAQs added successfully.')
          this.router.navigate(['/faq'])
         // this.customerListArr = res['result']['docs'];
  
        }
      }, err => {
  
         this.service.hideSpinner(); 
        if (err['responseCode'] == '401') {
          this.service.onLogout();
          this.toastr.error('Unauthorized Access');
        } else {
          this.toastr.error('Something Went Wrong');
        }
      })
    }
  
}
