import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { CustomerManagementComponent } from './component/customer-management/customer-management.component';
import { MyProfileComponent } from './component/my-profile/my-profile.component';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { StaticContentComponent } from './component/static-content/static-content.component';
import { ViewCustomerComponent } from './component/view-customer/view-customer.component';
import { AddCustomerComponent } from './component/add-customer/add-customer.component';
import { SupportComponent } from './component/support/support.component';
import { ViewSupportComponent } from './component/view-support/view-support.component';
import { FaqComponent } from './component/faq/faq.component';
import { AddFaqComponent } from './component/add-faq/add-faq.component';
import { ViewFaqComponent } from './component/view-faq/view-faq.component';
import { EditFaqComponent } from './component/edit-faq/edit-faq.component';
import { EditStaticContentComponent } from './component/edit-static-content/edit-static-content.component';
import { EditCustomerComponent } from './component/edit-customer/edit-customer.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { AuthenticationComponent } from './component/authentication/authentication.component';
import { AuthenticationFailureComponent } from './component/authentication-failure/authentication-failure.component';
import { ResourceManagementComponent } from './resource-management/resource-management.component';
import { ViewBannerComponent } from './resource-management/view-banner/view-banner.component';
import { EditBannerComponent } from './resource-management/edit-banner/edit-banner.component';
import { ViewDiseaseComponent } from './resource-management/view-disease/view-disease.component';
import { EditDiseaseComponent } from './resource-management/edit-disease/edit-disease.component';
import { AddNewDiseaseComponent } from './resource-management/add-new-disease/add-new-disease.component';



const routes: Routes = [
  {
    path:'',
    redirectTo: "/login",
    pathMatch :"full"
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'customer-management',
    component: CustomerManagementComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  
  {
    path: 'my-profile',
    component: MyProfileComponent
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'static-content',
    component: StaticContentComponent
  },
  { path: 'edit-static-content/:id', component: EditStaticContentComponent},
  { path: 'view-customer/:id', component: ViewCustomerComponent},
  { path: 'add-customer', component: AddCustomerComponent},
  { path: 'edit-customer/:id', component: EditCustomerComponent},
  { path: 'support', component: SupportComponent},
  { path: 'view-support/:id', component: ViewSupportComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'add-faq',component: AddFaqComponent},
  { path: 'view-faq/:id', component: ViewFaqComponent},
  { path: 'edit-faq/:id', component: EditFaqComponent},
  { path: 'reset-password/:id', component: ResetPasswordComponent},
  { path: 'authentication', component: AuthenticationComponent},
  { path: 'authentication-failure', component: AuthenticationFailureComponent},
  { path: 'resource-management', component:ResourceManagementComponent},
  { path: 'view-banner/:id', component:ViewBannerComponent},
  { path: 'edit-banner/:id', component:EditBannerComponent},
  { path: 'view-disease/:id', component: ViewDiseaseComponent},
  { path: 'edit-disease/:id', component: EditDiseaseComponent},
  { path: 'add-new-disease', component: AddNewDiseaseComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
