import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;
  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
  ) { }

  ngOnInit() {

    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      confirmPassword : new FormControl('',Validators.required)      
    })
  }
  changePasswordFunc(){
    var apireq = {
      'password': this.resetPasswordForm.value.oldPassword,
      'newPassword': this.resetPasswordForm.value.password,
      
    }
    console.log("data::::", apireq)
    this.service.showSpinner();
    this.service.post('api/v1/admin/chnagePassword',apireq).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
        this.toastr.success('Password Set Successfully');
        this.resetPasswordForm.reset()
       // this.router.navigate(['/login'])
      }
      else if(res['responseCode']==402){
        this.toastr.error('Old password is wrong.')
      }
    }, err=>{
    
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })

    
  }
  back(){
    this.router.navigate(['/my-profile'])
  }
}
