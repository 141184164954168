import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {
  custDetail: any;
  custId: any;
  editCustomerForm: FormGroup
  selectedState: any;
  cityArr: any;
  stateArr: any;
  file: any;
  imageType: any;
  imageUrl1: any;
  custcity: any;

  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.custId = (params['id']); // (+) converts string 'id' to a number
   
       localStorage.setItem('custId',this.custId)
       });
       this.viewDetail();
       this.selectState()
       this.service.BlockFuture()
       this.onDateChanged()
       this.phoneformat()
     
       console.log("custcity", this.custcity)
      
       {
        this.editCustomerForm = new FormGroup({
          firstName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.maxLength(40), Validators.required]),
          lastName: new FormControl('', [Validators.pattern(/^[a-z ,.'-]+$/i), Validators.maxLength(40), Validators.required]),
          email: new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/i)]),
          contact: new FormControl('', [Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
          dob: new FormControl('', []),
          address: new FormControl('', [Validators.required]),
          address1: new FormControl('', [Validators.required]),
          country: new FormControl('', [Validators.pattern(/^[a-z ,.'-]+$/i),Validators.required]),
          gender: new FormControl('', [Validators.required]),
          imageUrl: new FormControl(''),
          password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
          confirmPassword: new FormControl('', []),
          state: new FormControl('', [Validators.required]),
          city: new FormControl('', [Validators.required]),
      },)
      }
  }

  get firstName(): any {
    return this.editCustomerForm.get('firstName')
}
get lastName(): any {
    return this.editCustomerForm.get('lastName')
    
}
get password(): any {
    return this.editCustomerForm.get('password');
}
get confirmPassword(): any {
    return this.editCustomerForm.get('confirmPassword');
}
get email(): any {
    return this.editCustomerForm.get('email')
}
get contact(): any {
    return this.editCustomerForm.get('contact')
}
get dob(): any {
    return this.editCustomerForm.get('dob')
}
get address(): any {
    return this.editCustomerForm.get('address')
}
get address1(): any {
  return this.editCustomerForm.get('address1')
}
get country(): any {
    return this.editCustomerForm.get('country')
}

get gender(): any {
    return this.editCustomerForm.get('gender')
}
get state(): any {
  return this.editCustomerForm.get('state')
}
get city(): any {
  return this.editCustomerForm.get('city')
}
get imageUrl(): any {
    return this.editCustomerForm.get('imageUrl')
}

  viewDetail(){
  
    var url = 'api/v1/admin/viewCustomer/'+ this.custId;
    this.service.showSpinner();
    this.service.get(url).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
       this.custDetail = res['result']
       console.log("cust details:", this.custDetail)
       this.imageUrl1 = this.custDetail.profilePic
       //this.custcity = this.custDetail.city
       var data = {
        'state': this.custDetail.state,
      }
      this.service.post('api/v1/admin/cityList', data).subscribe((res) => {
          this.service.hideSpinner();
          if (res['responseCode'] == 200) {
              this.cityArr = res['result'];  
          }
         else if (res['responseCode'] == 404) {
          this.toastr.error('Please select state first.');
        }
          else {
              this.toastr.error(res['message']);
              this.service.hideSpinner();
          }
    
      }, (err) => {
          this.service.hideSpinner();
      })
       this.editCustomerForm.patchValue({
        'firstName' :this.custDetail.firstName ,
        'lastName': this.custDetail.lastName ,
        'email': this.custDetail.email,
        'contact': this.custDetail.phoneNumber,
        'address': this.custDetail.addressLine1,
        'address1': this.custDetail.addressLine2,
        'gender': this.custDetail.gender,
        'city':  this.custDetail.city,
        'state': this.custDetail.state,
        'dob': this.custDetail.birthday,
       
       
       })
       console.log("city:", this.custDetail.city)
      
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }

  editCustomer(){
    // if(!this.imageUrl1){
    //   this.toastr.error('Please upload your profile picture.')
    //    }
    
      let data = {
         "userId": this.custId,
        "address1": this.editCustomerForm.value.address,
        "address2": this.editCustomerForm.value.address1,
        "email": this.editCustomerForm.value.email,
        "gender": this.editCustomerForm.value.gender,
        "firstName": this.editCustomerForm.value.firstName,
        "lastName": this.editCustomerForm.value.lastName,
        "phoneNumber":  this.editCustomerForm.value.contact,
        "password": this.editCustomerForm.value.password,
        "confirmPassword": this.editCustomerForm.value.confirmPassword,
        "birthday": this.editCustomerForm.value.dob,
        "state":this.selectedState,
        "city":this.editCustomerForm.value.city,
        "profilePic": this.imageUrl1 ? this.imageUrl1 : this.custDetail.profilePic
        
    }
    this.service.showSpinner();
    this.service.putApi('api/v1/admin/editCustomer', data).subscribe((res) => {
        this.service.hideSpinner();
        if (res['body']['responseCode'] == 200) {
          this.toastr.success("Customer details updated successfully");
            this.router.navigate(['/customer-management']);
                             
        }
        if (res['responseCode'] == 205) {
          this.service.hideSpinner();
         
      }
     
      
    }, (err) => {
        this.service.hideSpinner();
  
    })
       
  }


  ValidateFileUpload(event) {
    this.file = event.target.files;
    if (this.file[0]) {
      this.imageType = this.file[0].type;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl1 = e.target.result;
        //console.log(this.imageUrl1)
      };
      reader.readAsDataURL(this.file[0]);
    }
  }
  selectState(){
    
    this.service.get('api/v1/admin/stateList').subscribe((res) => {
      //  this.service.hideSpinner();
        if (res['responseCode'] == 200) {
            this.stateArr = res['result'];
          
  
        }
        else {
            this.toastr.error(res['message']);
           // this.service.hideSpinner();
        }
  
    }, (err) => {
        //this.service.hideSpinner();
    })
  }

  searchState(event) {
    this.service.showSpinner()
    this.selectedState = event.target.value
    var data = {
      'state': this.selectedState,
    }
    this.service.post('api/v1/admin/cityList', data).subscribe((res) => {
        this.service.hideSpinner();
        if (res['responseCode'] == 200) {
            this.cityArr = res['result'];  
        }
        else if (res['responseCode'] == 404) {
          this.toastr.error('Please select state first.');
        }
        else {
            this.toastr.error(res['message']);
            this.service.hideSpinner();
        }
  
    }, (err) => {
        this.service.hideSpinner();
    })
  }


   // Date of Birth Management
   public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
};
onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
        year: d.getFullYear(),
        month: d.getMonth()+1,
        day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
}
//Returns copy of myDatePickerOptions
getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
}

phoneformat(){
  const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };
  
  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };
  
  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
      event.preventDefault();
    }
  };
  
  const formatToPhone = (event) => {
    if(isModifierKey(event)) {return;}
  
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);
  
    if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
    else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
    else if(input.length > 0){target.value = `(${zip}`;}
  };
  
  const inputElement = document.getElementById('phoneNum');
  inputElement.addEventListener('keydown',enforceFormat);
  inputElement.addEventListener('keyup',formatToPhone);
  
}

}
