import { Component, OnInit } from '@angular/core';
import { Router,Event,NavigationEnd } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
declare var $:any;
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  profileData: any;
  isLoggedIn: boolean;
  currUrl: any;

  constructor(private router : Router,
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule) {

      router.events.subscribe( (event: Event) => {
        if(event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        
        if(localStorage.data) {
        this.service.changeLoginSub('login')
        if((this.currUrl == `login`|| this.currUrl ==`forgot-password`|| this.currUrl ==`reset-password`|| this.currUrl ==``)) {
        this.router.navigate([`/dashboard`])
        }
        } else {
        if(!(this.currUrl == `login`|| this.currUrl ==`forgot-password` || this.currUrl.includes(`reset-password`)|| this.currUrl ==``)) {
        this.router.navigate([`/login`])
        }
        this.service.changeLoginSub('logout');
        }
        }
        })
     }

  ngOnInit() {
    this.service.loginObs.subscribe(response => { 
      console.log('response----->>',response)
      if(response == 'login') {
        this.myProfile();
      this.isLoggedIn = true; 
      }
      else {
      this.isLoggedIn = false; 
      } 
      console.log(this.isLoggedIn)
      })

  if(localStorage.getItem('Auth')){
 this.myProfile();
      this.isLoggedIn = true; 
  }
  else {
    this.isLoggedIn = false; 
    } 
    console.log(this.isLoggedIn)
  }
  myProfile(){
    // this.service.showSpinner();
     this.service.get('api/v1/admin/getProfile').subscribe(res=>{
     
      // this.service.hideSpinner();
       if(res['responseCode']== 200){      
      this.profileData= res['result']
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
     
       //this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
   }
   openLogoutModal(){
    $('#signout_modal').modal('show');
   }
  onLogout(){
    this.service.showSpinner()
    $('#signout_modal').modal('hide');
   // this.router.navigate(['']);
    localStorage.removeItem('data');
   localStorage.removeItem('Auth');
    localStorage.removeItem('userId');
    this.service.hideSpinner()
     window.location.replace('/login')
    
  }

}
