import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {
  editFaqForm: FormGroup
  faqId: any;
  FaqListArr: any;
  faqDetail: any;
  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {

    let obj = this.route.params.subscribe(params => {
      this.faqId = (params['id']); // (+) converts string 'id' to a number
   
      // localStorage.setItem('faqId',this.faqId)
       });
    this.editFaqForm = new FormGroup({
      'topic' : new FormControl('',),  
      'question' : new FormControl('',), 
      'answer' : new FormControl('',), 
      
    })
    this.viewFaq()
  }

  get topic(): any {
    return this.editFaqForm.get('topic')
}
get question(): any {
  return this.editFaqForm.get('question')
}
get answer(): any {
  return this.editFaqForm.get('answer')
}


viewFaq(){
  
  var url = 'api/v1/faq/faqs/'+ this.faqId;
  this.service.showSpinner();
  this.service.get(url).subscribe(res=>{
   
    this.service.hideSpinner();
    if(res['responseCode']==200){
     this.faqDetail = res['result']
     this.editFaqForm.patchValue({
      'topic' :this.faqDetail.topic ,
      'question': this.faqDetail.question ,
      'answer': this.faqDetail.answer
      
     })
     
    }else {
      this.toastr.error(res['message']);
    }
  },err=>{
   
    this.service.hideSpinner();
    if(err['responseCode']=='401'){
      this.service.onLogout();
      this.toastr.error('Unauthorized Access');
    }else{
    this.toastr.error('Something Went Wrong');
 }
  })
}


editFaq(){
  let data={
    'faqId': this.faqId,
    'topic:': this.editFaqForm.value.topic,
    'question': this.editFaqForm.value.question,
    'answer': this.editFaqForm.value.answer
  }
  var url = 'api/v1/faq/faqs';
  this.service.showSpinner();
  this.service.putApi(url,data).subscribe(res=>{
   console.log(res)
    this.service.hideSpinner();
    if(res['body']['responseCode']=='200'){
    this.toastr.success('FAQ updated successfully')
    this.router.navigate(['/faq'])
     
    }else {
      this.toastr.error(res['message']);
    }
  },err=>{
   
    this.service.hideSpinner();
    if(err['responseCode']=='401'){
      this.service.onLogout();
      this.toastr.error('Unauthorized Access');
    }else{
    this.toastr.error('Something Went Wrong');
 }
  })
}

}
