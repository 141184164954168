import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-edit-disease',
  templateUrl: './edit-disease.component.html',
  styleUrls: ['./edit-disease.component.css']
})
export class EditDiseaseComponent implements OnInit {
  diseaseId: any;
  addform: FormGroup;
  diseaseDetail: any;
  imageUrl1: any;
  file: any;
  imageType: any;
  constructor(private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute) { }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.diseaseId = (params['id']); // (+) converts string 'id' to a number
       });
       this.viewDetail();
       this.addform = new FormGroup({
        "editorValue": new FormControl('', ([Validators.required])),
        "title": new FormControl('', ([Validators.required, Validators.minLength(3),Validators.pattern(/^[^\s][A-Za-z&\s]*$/)]))
       });
       localStorage.setItem('curTab','Desase')
  }

  ValidateFileUpload(event) {
    this.file = event.target.files;
    console.log("File url:::", this.file)
    if (this.file[0].type == 'video/mp4' || this.file[0].type == 'video/avi' || this.file[0].type == 'video/quicktime') {
      if (this.file[0].size <= 25000000){
      this.imageType = this.file[0].type;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl1 = e.target.result;
        console.log(this.imageUrl1)
      };
      reader.readAsDataURL(this.file[0]);
    }
    else{
      this.toastr.error('File size must be less than 25Mb')
    }
    }
  }

  viewDetail(){
  
    var url = 'api/v1/admin/viewResource/'+ this.diseaseId;
    this.service.showSpinner();
    this.service.get(url).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
       this.diseaseDetail = res['result']
       this.imageUrl1 = this.diseaseDetail.link
       this.addform.patchValue({
        title : this.diseaseDetail.title,
        editorValue : this.diseaseDetail.description
       });
      
       
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
       
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }

  updateDisease(){
    let data={
      'resourceId': this.diseaseId,
      'link': this.imageUrl1,
       'title': this.addform.value.title,
       'description': this.addform.value.editorValue,
      
     }
     var url = 'api/v1/admin/editResource';
     this.service.showSpinner();
     this.service.putApi(url,data).subscribe(res=>{
      console.log(res)
       this.service.hideSpinner();
       if(res['body']['responseCode']=='200'){
       this.toastr.success('Disease updated successfully')
       this.router.navigate(['resource-management/'])
        
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
      
       this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
  }

  back(){
    this.router.navigate(['resource-management/'])
  }
}
