import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  supportListArr: any=[];
  page: any;
  searchText: any;

  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
  ) { }

  ngOnInit() {
    this.supportListFunc()
  }

  searchValue(value){
    this.searchText=value
    console.log('searchByName',this.searchText)
    this.supportListFunc();
  }
  supportListFunc(){

    var data = {
      'search': this.searchText ,
      'page': this.page,
      'limit': 500,
      
    }
    console.log("data::::", data)
    this.service.showSpinner();
    this.service.post('api/v1/admin/supportList',data).subscribe(res=>{
     console.log("RES------",res)
      this.service.hideSpinner();
      if(res['responseCode']==200){
        this.toastr.success('Support data fetched successfully');
        this.supportListArr= res['result']['docs']
       this.page = res['result']['page']
       
      }
    }, err=>{
    
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })

    
  }

  viewSupportDetails(id){
    this.router.navigate(['view-support/'+ id])
  }
}
