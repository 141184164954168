import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginData : any;
  Obj: any;
  id: any;
  showEye: boolean = false;
  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
   
  ) { }

  ngOnInit() {
    this.checkInput();
    
    
  }

  checkInput(){
    let remData=JSON.parse(localStorage.getItem('rememberMe'))?JSON.parse(localStorage.getItem('rememberMe')):'';
    let pass=''
    if(remData!=''){
      pass=window.atob(remData.password)
      }
  
    this.loginForm = this.fb.group({
      email: new FormControl(remData.email,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      password : [pass,[Validators.required]],
      rememberMe : new FormControl(false)
      
    })
  }
  onLogin(){
    this.service.showSpinner();
    this.service.post("api/v1/admin/login", {
      email : this.loginForm.value.email,
      password : this.loginForm.value.password
    }).subscribe(
      (res : any)=>{
       
         this.service.hideSpinner();
         if(res['responseCode'] == '200'){
           //console.log("hiiiiiii")
          // this.myProfile()
          this.toastr.success('Login successfully')
          this.id= res.result.userId
          console.log("user id:---->>", this.id)
       //  localStorage.setItem('Auth',res['data']['token']);
          localStorage.setItem('userId',this.id);
          localStorage.setItem('Auth',res.result.token);
         if(this.loginForm.value.rememberMe==true){
          let remData={
            "email":this.loginForm.value.email,
            "password":window.btoa(this.loginForm.value.password)
          }
          localStorage.setItem('rememberMe',JSON.stringify(remData))
    
      }
  
         this.service.changeLoginSub('login');
      
         this.router.navigate(['/dashboard']);
         }
         else if (res['responseCode'] == '404'){
          this.toastr.error('Unauthorized access')
         }
         else if (res['responseCode'] == '402'){
          this.toastr.error('Invalid credentials')
         }
      },
      (err : any)=>{
      
        // this.service.hideSpinner();
        // if(err['status']=='401'){
        //   this.service.toasterErr('Unauthorized Access');
        // }else{
        // this.service.toasterErr('Something Went Wrong');
     //}
      }
    )
  this.Obj={
   'email' : this.loginForm.value.email,
  
  }
  
    localStorage.setItem('data',JSON.stringify(this.Obj));
    
  }

  myProfile(){
     this.service.showSpinner();
     this.service.get('api/v1/admin/getProfile').subscribe(res=>{
     
       this.service.hideSpinner();
       if(res['responseCode']== 200){      
     // this.profileData= res['result']
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
     
       this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
   }

   /** to show and hide password */
  showHidePassword() {
    if (this.showEye == false) {
      this.showEye = true;
    } else if (this.showEye == true) {
      this.showEye = false;
    }
  }

}
