import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  FaqListArr: any=[];
  delete_id: any;
  pageNo: 1;
  limit: 250;
  searchText: any;
  faqarr: any;
  page: any;

  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule
  ) { }

  ngOnInit() {
    this.getFaqList()
  }

  searchValue(searchval){
    this.searchText= searchval
  }
  getFaqList() {
    this.service.showSpinner();
    let data = {
        'search':  this.searchText,
         'page': this.pageNo,
        'limit': 500,
    }
     
    this.service.post('api/v1/faq/faqsList', data).subscribe(res => {  
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.toastr.success('FAQs fetched successfully.')
       this.FaqListArr= res['result']['docs']
       this.page= res['result']['page']

      }
      if (res['responseCode'] == 404) {
        this.service.hideSpinner();
        this.toastr.error('No Data Found.')
      }
    }, err => {

       this.service.hideSpinner(); 
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      } else {
        this.toastr.error('Something Went Wrong');
      }
    })
  }
  addFaq(){
    this.router.navigate(['/add-faq'])
  }
  faqdetail(id){
    this.router.navigate(['view-faq/'+ id])
  }
  editModal(id){
    this.router.navigate(['edit-faq/'+ id])
  }
  deleteModal(id){
    this.delete_id=id
    $('#deleteModal').modal('show')
  }

  deleteFaq(){
    var url = 'api/v1/faq/faqs/'+ this.delete_id;
     this.service.showSpinner();
     this.service.deleteApi(url).subscribe(res=>{
       $('#deleteModal').modal('hide')
       this.service.hideSpinner();
       if(res['responseCode']==200){
       this.toastr.success('FAQ removed successfully.')
       this.getFaqList();
        
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
      
       this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
  }
}
