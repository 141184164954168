import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
  ) { }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
    
      password: new FormControl('',Validators.required),
      confirmPassword : new FormControl('',Validators.required)      
    })
  }

  resetPasswordFunc(){
    let id= localStorage.getItem('userId')
    var apireq = {
      'confirmPassword': this.resetPasswordForm.value.confirmPassword,
      'newPassword': this.resetPasswordForm.value.password,
      
    }
    //console.log("data::::", apireq)
    this.service.showSpinner();
    this.service.post('api/v1/admin/resetPassword/' +id ,apireq).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
        this.toastr.success('Password Set Successfully');
        this.resetPasswordForm.reset()
       // this.router.navigate(['/login'])
      }
    }, err=>{
    
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })

    
  }
}
