import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})
export class EditBannerComponent implements OnInit {
  bannerId: any;
  bannerDetail: any;
  editform: FormGroup;
  constructor( private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute) { }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.bannerId = (params['id']); // (+) converts string 'id' to a number
   
       //localStorage.setItem('custId',this.custId)
       });
       this.viewDetail();
       this.editform = new FormGroup({
        "editorValue": new FormControl('', ([Validators.required])),
        "title": new FormControl('', ([Validators.required, Validators.minLength(3),Validators.pattern(/^[^\s][A-Za-z&\s]*$/)]))
       });
       localStorage.setItem('curTab','banners')
  }
  viewDetail(){
  
    var url = 'api/v1/admin/viewBanner/'+ this.bannerId;
    this.service.showSpinner();
    this.service.get(url).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
       this.bannerDetail = res['result']
       this.editform.patchValue({
        title : this.bannerDetail.title,
        editorValue : this.bannerDetail.description
       });
      
       
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }

  back(){
    this.router.navigate(['resource-management/'])
  }

  update(){
    let data={
      'bannerId': this.bannerId,
       'title': this.editform.value.title,
       'description': this.editform.value.editorValue,
      
     }
     var url = 'api/v1/admin/editBanner';
     this.service.showSpinner();
     this.service.putApi(url,data).subscribe(res=>{
      console.log(res)
       this.service.hideSpinner();
       if(res['responseCode']=='200'){
       this.toastr.success('Banner updated successfully')
       this.router.navigate(['resource-management/'])
        
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
      
       this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
  }
}
