import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
declare var $: any;
@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.css']
})
export class CustomerManagementComponent implements OnInit {
  customerForm: FormGroup;
  customerListArr: any = [];
  delete_id: any;
  edit_id: any;
  page: 1
  searchText: any;
  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule
  ) { }

  ngOnInit() {
    this.getCustomerList()
  }

  searchValue(value){
    this.searchText=value
    console.log('searchByName',this.searchText)
    this.getCustomerList();
  }
  getCustomerList() {

    this.customerForm = new FormGroup({
      //'startdate': new FormControl('', Validators.required),
      //'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
    })
    let data = {
         'search': this.searchText ,
         'page': this.page,
         'limit': 500,
    }
    var url = "api/v1/admin/customerList";
      this.service.showSpinner();
    this.service.post(url, data).subscribe(res => {

       this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.toastr.success('Customer list fetched successfully.')
        this.customerListArr = res['result']['docs'];
        this.page = res['result']['page']
      }
      else if (res['responseCode'] == 404){
          this.toastr.error('No customer found.') 
      }
      else{
        this.toastr.error('Something went wrong.')
      }
    }, err => {

       this.service.hideSpinner(); 
      
    })
  }


  customerdetail(cust_id) {
    this.router.navigate(['view-customer/'+ cust_id])
  }

  addCustomer(){
    this.router.navigate(['add-customer/'])
  }
  openModal(id){
  this.delete_id=id
  $('#deleteModal').modal('show')
  }
  openEdit(id){
    this.router.navigate(['edit-customer/'+ id])
  }

  deleteCustomer(){
  
      var url = 'api/v1/admin/deleteCustomer/'+ this.delete_id;
      this.service.showSpinner();
      this.service.deleteApi(url).subscribe(res=>{
        $('#deleteModal').modal('hide')
        this.service.hideSpinner();
        if(res['responseCode']==200){
        this.toastr.success('Customer removed successfully.')
        this.getCustomerList();
         
        }else {
          this.toastr.error(res['message']);
        }
      },err=>{
       
        this.service.hideSpinner();
        if(err['responseCode']=='401'){
          this.service.onLogout();
          this.toastr.error('Unauthorized Access');
        }else{
        this.toastr.error('Something Went Wrong');
     }
      })
    }
  
  
}
