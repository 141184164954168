import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class MainserviceService {
  loginSub = new BehaviorSubject(``);
  loginObs = this.loginSub.asObservable();
  code: string;
  month: any;
  day: any;
  daily: string;
  year: number;
  dtToday: Date;
  maxDate: string;
  httpOptions: { headers: HttpHeaders; };
  
  //public baseUrl = "http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1536/" 
  //public baseUrl ="http://182.72.203.245:1804/"
  public baseUrl =" https://knowit-node.mobiloitte.com/"
  

  //websiteURL = 'https://localhost:4200/'
  websiteURL = 'http://ec2-35-176-66-190.eu-west-2.compute.amazonaws.com:1615/'
  constructor(public http: HttpClient,  public routes: Router, private spinner: NgxSpinnerService) { }

   // Header Managment 
   changeLoginSub(msg) {
    this.loginSub.next(msg);
  }


  // Api Functionlity 
  // Api Structuring Functionality
  post(url, data) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      };
    }
    return this.http.post(this.baseUrl + url, data, this.httpOptions);
  }

  get(url) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'token': `${this.code}` })
      }
    }
    return this.http.get(this.baseUrl + url, this.httpOptions);
  }
  getApi(url,data,header) {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
      this.httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${this.code}` })
      }
    }
    return this.http.get(this.baseUrl + url,this.httpOptions);
  }

  // Form Data Api Structure
  postApi(endPoint, data): Observable<any> {
    if (localStorage.getItem('Auth')) {
      this.code = localStorage.getItem('Auth')
    }
    if (localStorage.getItem('data') || localStorage.getItem('Auth')) {
     
      this.httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${this.code}` })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, this.httpOptions);
  }

  deleteApi(url) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "token": localStorage.getItem('Auth')
      }), observe: 'response'
    }
    return this.http.delete(this.baseUrl + url, this.httpOptions);
  }
 

  putApi(url,data){
    var httpOptions;
    httpOptions={
      headers: new HttpHeaders({
         "Content-Type": "application/json",
        "token": localStorage.getItem('Auth')
      }),observe: 'response'
    }
    return this.http.put(this.baseUrl + url,data,httpOptions)
  }
  // Logout
  onLogout() {
    localStorage.clear();
    // window.location.reload();
    this.routes.navigate(['/login']);
    // $('#signout_modal').modal('hide');
  }

  showSpinner(){
  this.spinner.show()
}
hideSpinner(){
  this.spinner.hide()
}
BlockFuture() {
  $(() => {
    this.dtToday = new Date();
    this.month = this.dtToday.getMonth() + 1;
    this.day = this.dtToday.getDate();
    this.year = this.dtToday.getFullYear();
    if (this.month < 10)
      this.month = '0' + this.month.toString();
    if (this.day < 10)
      this.day = '0' + this.day.toString();
    this.maxDate = this.year + '-' + this.month + '-' + this.day;
    $('#fromDate').attr('max', this.maxDate);
    $('#toDate').attr('max', this.maxDate);
  });
}
}


