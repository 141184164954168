import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css']
})
export class StaticContentComponent implements OnInit {
  staticList: any=[];

  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
  ) { }

  ngOnInit() {
    this.getListCode()
  }
  getListCode(){
    let data={

    }
    this.service.showSpinner();
    this.service.post('api/v1/static/staticList',data).subscribe(res=>{
   
      this.service.hideSpinner();
      if(res['responseCode']== 200){
       this.staticList = res['result'];
      
      }
    }, err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Page Not Found');
   }
    })
  }

  editModal(id){
    this.router.navigate(['edit-static-content/'+ id])
  }
}
