import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup;
  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
  ) { }

  ngOnInit() {
    this.forgotPassword = new FormGroup({
     
      'email': new FormControl('',Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)),
    })
  }


    onForgot(){
      var data = {
        'email':this.forgotPassword.value.email
      }
      console.log("data::::", data)
      this.service.showSpinner();
      this.service.post('api/v1/admin/forgotPassword',data).subscribe(res=>{
       
        this.service.hideSpinner();
        if(res['responseCode']==200){
          this.toastr.success('Link has been successfully sent to registered email id.');
          this.router.navigate(['/login'])
        }
        else if(res['responseCode']==404){
          this.toastr.error('Provided email is not registered')
        }
      }, err=>{
      
        this.service.hideSpinner();
        if(err['responseCode']=='401'){
          this.toastr.error('Unauthorized Access');
        }
        else if(err['responseCode']=='404'){
          this.toastr.error('Email not registered.');
        }
        else{
        this.toastr.error('Something Went Wrong');
     }
      })
  
      
    }
  
    onResend(){
      this.onForgot();
    }
}
