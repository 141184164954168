import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-edit-static-content',
  templateUrl: './edit-static-content.component.html',
  styleUrls: ['./edit-static-content.component.css']
})
export class EditStaticContentComponent implements OnInit {
  static_id: any;
  staticDetail: any;
  form: FormGroup;

  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {

    let obj = this.route.params.subscribe(params => {
      this.static_id = (params['id']); // (+) converts string 'id' to a number
   
      // localStorage.setItem('custId',this.custId)
       });
       this.viewStatic()

       this.form = new FormGroup({
        "editorValue": new FormControl('', ([Validators.required])),
        "Title": new FormControl('', ([Validators.required, Validators.minLength(3),Validators.pattern(/^[^\s][A-Za-z&\s]*$/)]))
       });
  }


  viewStatic(){
  
    var url = 'api/v1/static/viewStaticPage/'+ this.static_id;
    this.service.showSpinner();
    this.service.get(url).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
       this.staticDetail = res['result']
       this.form.patchValue({
        Title : this.staticDetail.title,
        editorValue : this.staticDetail.description
       });
       
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }

  updateStatic(){
   
      let data={
       'staticId': this.static_id,
        'title': this.form.value.Title,
        'description': this.form.value.editorValue,
       
      }
      var url = 'api/v1/static/editStaticPage';
      this.service.showSpinner();
      this.service.putApi(url,data).subscribe(res=>{
       console.log(res)
        this.service.hideSpinner();
        if(res['body']['responseCode']=='200'){
        this.toastr.success('Static content updated successfully')
        this.router.navigate(['/static-content'])
         
        }else {
          this.toastr.error(res['message']);
        }
      },err=>{
       
        this.service.hideSpinner();
        if(err['responseCode']=='401'){
          this.service.onLogout();
          this.toastr.error('Unauthorized Access');
        }else{
        this.toastr.error('Something Went Wrong');
     }
      })
    }
    
  

}
