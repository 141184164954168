import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
declare var CanvasJS: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  custList: any;
  graphData: any;
  month: any;
  count: any;
  label6: string;
  year: number;
  day: number;
   monthNames = [ "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December" ];
  arrgraph: any=[];

  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule
  ) {
    this.getGraphData()
  }

  ngOnInit() {
this.getDashboard()
this.getGraphData()
  }

  getDashboard(){
    let data={

    }
    this.service.showSpinner();
    this.service.post('api/v1/admin/dashboard',data).subscribe(res=>{
   
      this.service.hideSpinner();
      if(res['responseCode']== 200){
       this.custList = res['result'];
      this.chart()
      }
    }, err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Spmething went wrong');
   }
    })
  }
  getGraphData(){
    this.service.showSpinner();
    this.service.get('api/v1/admin/getSomeGraphData').subscribe(res=>{
   
      this.service.hideSpinner();
      console.log("RESPONSE---->", res)
      if(res['responseCode']== 200){
       this.graphData = res['result'][0];
       console.log('graphdata', this.graphData)
       this.arrgraph=[]
       for(var it in this.graphData){
         console.log(it, this.graphData[it])

         this.arrgraph.push({
           y: this.graphData[it],
           label: it
         })
       }
       console.log("ARRGRAPH", this.arrgraph)
      //  this.arrgraph=[]
      //  this.graphData.forEach(element => {
      //    this.arrgraph.push({
      //      y: Number(element.count),
      //      label: this.monthNames[element.month],
      //    })
      //    console.log("graphdata", this.arrgraph)
      //  });

      this.chart()
      }
    }, err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Page Not Found');
   }
    })
  }

  

chart(){
  
  var chart = new CanvasJS.Chart("chartContainer", {
     animationEnabled: true,
     theme: "light2",
    // title:{
    //   //text: "Simple Line Chart"
    // },
    // axisY:{
    //   //includeZero: false
    //   title: "Users",
    //   //minimum: 0,
    //   //interval: 10
    // },
     axisX:{
    //   //includeZero: false
    //   //minimum: 1,
       interval: 1,
    //   title: "Month",
    //   //intervalType: "month",
      
     },
    data: [{        
      type: "line",
          indexLabelFontSize: 16,
          dataPoints: this.arrgraph
      // dataPoints: [
        
      //     { x:this.arrgraph.label, y: this.arrgraph.y},
      //   //  { x:this.month, y: this.count,label:  "Feb"},
      //   //  { x:this.month, y: this.count,label:  "Mar"},
      //   //  { x:this.month, y: this.count,label:  "Jan"},
      //   //  { x:this.month, y: this.count,label:  "Jan"},
      //   //  { x:this.month, y: this.count,label:  "Jan"},
      //   //  { x:this.month, y: this.count,label:  "Jan"},
      //   //  { x:this.month, y: this.count,label:  "Jan"},
      //   //  { x:2, y: this.count},
      //   //  { x:3, y: this.count},
      //   //  { x:4, y: this.count},
      //   //  { x:5, y: this.count},
      //   //  { x:6, y: this.count},
      //   //  { x:7, y: this.count},
      //   //  { x:8, y: this.count},
      //   //  { x:9, y: this.count},
      //   //  { x:10, y: this.count},
      //   //  { x:11, y: this.count},
      //   //  { x:12, y: this.count},
      //  // { x: 1 , y:10 },
      //   // { y: 414},
      //   // { y: 520, indexLabel: "\u2191 highest",markerColor: "red", markerType: "triangle" },
      //   // { y: 460 },
      //   // { y: 450 },
      //   // { y: 500 },
      //   // { y: 480 },
      //   // { y: 480 },
      //   // { y: 410 , indexLabel: "\u2193 lowest",markerColor: "DarkSlateGrey", markerType: "cross" },
      //   // { y: 500 },
      //   // { y: 480 },
      //   // { y: 510 }
      // ]
    }]
  });
  chart.render();
  
    
}
monthJson: any = [
  {
    "1":"Jan",
    "2":"Feb",
    "3":"Mar",
    "6":"Jun"
  }
]
  
}
