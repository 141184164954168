import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  profileData: any;
  editForm: FormGroup;
  editId: any;

  constructor(
    private router : Router,
  
    private fb: FormBuilder,
    private http : HttpClient,
    private service : MainserviceService,
    private toastr: ToastrService,
    private browseranimation :BrowserAnimationsModule,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.editId = (params['id']); // (+) converts string 'id' to a number
   
      // localStorage.setItem('faqId',this.faqId)
       });

    this.editForm = new FormGroup({
      'name' : new FormControl('',Validators.pattern(/^[a-zA-Z ]*$/i)),  
      'email': new FormControl('',Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      
    })
    this.myProfile()
  }

  myProfile(){
    var data={
    "userId": localStorage.getItem("userId")
    }
    this.service.showSpinner();
    this.service.get('api/v1/admin/getProfile').subscribe(res=>{
    
      this.service.hideSpinner();
      if(res['responseCode']== 200){      
     this.profileData= res['result']

     this.editForm.patchValue({
      'name' :this.profileData.name,
      'email': this.profileData.email ,
      
     })
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
    
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }

  edit(){
    let data={
     
      'name': this.editForm.value.name,
      'email': this.editForm.value.email,
     
    }
    var url = 'api/v1/admin/editProfile';
    this.service.showSpinner();
    this.service.putApi(url,data).subscribe(res=>{
     console.log(res)
      this.service.hideSpinner();
      if(res['body']['responseCode']=='200'){
      this.toastr.success('Profile updated successfully')
      this.router.navigate(['/my-profile'])
       
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }
  
}
