import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-add-new-disease',
  templateUrl: './add-new-disease.component.html',
  styleUrls: ['./add-new-disease.component.css']
})
export class AddNewDiseaseComponent implements OnInit {
  file: any;
  imageUrl1: any;
  imageType: any;
  addform: FormGroup;
  constructor(private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute) { }

  ngOnInit() {
    this.addform = new FormGroup({
      "editorValue": new FormControl('', ([Validators.required])),
      "imageUrl": new FormControl('',),
      "title": new FormControl('', ([Validators.required, Validators.minLength(3),Validators.pattern(/^[^\s][A-Za-z&\s]*$/)]))
     });
  }

  ValidateFileUpload(event) {
    this.file = event.target.files;
    console.log("File url:::", this.file)
    if (this.file[0].type == 'video/mp4' || this.file[0].type == 'video/avi' || this.file[0].type == 'video/quicktime') {
      if (this.file[0].size <= 25000000){
      this.imageType = this.file[0].type;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl1 = e.target.result;
        console.log(this.imageUrl1)
      };
      reader.readAsDataURL(this.file[0]);
    }
    else{
      this.toastr.error('File size must be less than 25Mb')
    }
    }
  }

  addNewDisease(){
    let data={
      'link': this.imageUrl1,
       'title': this.addform.value.title,
       'description': this.addform.value.editorValue,
      
     }
     var url = 'api/v1/admin/addResource';
     this.service.showSpinner();
     this.service.post(url,data).subscribe(res=>{
      console.log(res)
       this.service.hideSpinner();
       if(res['responseCode']=='200'){
       this.toastr.success('Disease added successfully')
       this.router.navigate(['resource-management/'])
        
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
      
       this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
  }

  back(){
    this.router.navigate(['resource-management/'])
  }

}
