import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { MainserviceService } from './mainservice.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideMenuComponent } from './component/side-menu/side-menu.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { CustomerManagementComponent } from './component/customer-management/customer-management.component';
import { MyProfileComponent } from './component/my-profile/my-profile.component';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { StaticContentComponent } from './component/static-content/static-content.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ViewCustomerComponent } from './component/view-customer/view-customer.component';
import { AddCustomerComponent } from './component/add-customer/add-customer.component';
import { MyDatePickerModule } from 'mydatepicker';
import { SupportComponent } from './component/support/support.component';
import { ViewSupportComponent } from './component/view-support/view-support.component';
import { FaqComponent } from './component/faq/faq.component';
import { AddFaqComponent } from './component/add-faq/add-faq.component';
import { ViewFaqComponent } from './component/view-faq/view-faq.component';
import { EditFaqComponent } from './component/edit-faq/edit-faq.component';
import { EditStaticContentComponent } from './component/edit-static-content/edit-static-content.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { EditCustomerComponent } from './component/edit-customer/edit-customer.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthenticationComponent } from './component/authentication/authentication.component';
import { AuthenticationFailureComponent } from './component/authentication-failure/authentication-failure.component';
import { ResourceManagementComponent } from './resource-management/resource-management.component';
import { ViewBannerComponent } from './resource-management/view-banner/view-banner.component';
import { EditBannerComponent } from './resource-management/edit-banner/edit-banner.component';
import { EditDiseaseComponent } from './resource-management/edit-disease/edit-disease.component';
import { ViewDiseaseComponent } from './resource-management/view-disease/view-disease.component';
import { AddNewDiseaseComponent } from './resource-management/add-new-disease/add-new-disease.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SideMenuComponent,
    DashboardComponent,
    CustomerManagementComponent,
    MyProfileComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    StaticContentComponent,
    ViewCustomerComponent,
    AddCustomerComponent,
    SupportComponent,
    ViewSupportComponent,
    FaqComponent,
    AddFaqComponent,
    ViewFaqComponent,
    EditFaqComponent,
    EditStaticContentComponent,
    EditCustomerComponent,
    ResetPasswordComponent,
    AuthenticationComponent,
    AuthenticationFailureComponent,
    ResourceManagementComponent,
    ViewBannerComponent,
    EditBannerComponent,
    EditDiseaseComponent,
    ViewDiseaseComponent,
    AddNewDiseaseComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(
      {
        maxOpened:1
      }
    ) ,
    BrowserAnimationsModule,
    NgxPaginationModule,
    MyDatePickerModule,
    CKEditorModule,
    NgxSpinnerModule,
  
  ],
  providers: [MainserviceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
