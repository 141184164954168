import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'knowit';
constructor(
  private router : Router,
  
  private fb: FormBuilder,
  private http : HttpClient,
  private service : MainserviceService,
  private toastr: ToastrService,
  private browseranimation :BrowserAnimationsModule
){

}
  ngOnInit() {
  //  this.myProfile()
  }


  myProfile(){
    // this.service.showSpinner();
     this.service.get('api/v1/admin/getProfile').subscribe(res=>{
     
      // this.service.hideSpinner();
       if(res['responseCode']== 200){      
     // this.profileData= res['result']
       }else {
         this.toastr.error(res['message']);
       }
     },err=>{
     
       //this.service.hideSpinner();
       if(err['responseCode']=='401'){
         this.service.onLogout();
         this.toastr.error('Unauthorized Access');
       }else{
       this.toastr.error('Something Went Wrong');
    }
     })
   }
}
