import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
declare var $: any;
@Component({
  selector: 'app-resource-management',
  templateUrl: './resource-management.component.html',
  styleUrls: ['./resource-management.component.css']
})
export class ResourceManagementComponent implements OnInit {
  currTab :any;
  bannerListArr: any;
  diseaseListArr: any;
  page: any=1;
  delete_id: any;
  diseaseListArrlength: any;
  constructor( private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule) { }

  ngOnInit() {
   this.defaultTab()
    this.getBannerList()
  var a =localStorage.getItem('curTab')
  if(a == 'Desase' ){
    this.currTab='Disease'
    this.getDiseaseList()
    localStorage.removeItem('curTab')
  }
  else if(a == 'banners'){
this.currTab='Banner'
this.getBannerList()
localStorage.removeItem('curTab')

  }
  else{
    this.currTab='Banner'
    this.getBannerList()
  }
  }

  defaultTab(){
    this.currTab = 'Banner';
  }
  selectTab(tab) {
    this.currTab = tab;
    console.log("curent tab: ", this.currTab)
    if( this.currTab == 'Disease'){
      this.getDiseaseList()
    }
    else{
      this.getBannerList()
    }
   
}
  getBannerList() {
    var url = "api/v1/user/getBanner";
      this.service.showSpinner();
    this.service.get(url).subscribe(res => {

       this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.toastr.success('Banner fetched successfully')
         this.bannerListArr = res['result'];
        // this.page = res['result']['page']
      }
      else if (res['responseCode'] == 404){
          this.toastr.error('No customer found') 
      }
      else{
        this.toastr.error('Something went wrong')
      }
    }, err => {

       this.service.hideSpinner(); 
      
    })
  }

  viewBanner(banner_id) {
    this.router.navigate(['view-banner/'+ banner_id])
  }
  editBanner(banner_id) {
    this.router.navigate(['edit-banner/'+ banner_id])
  }

  getDiseaseList() {
    let data={
      "page":this.page,
      "limit":1000
  }
    var url = "api/v1/admin/resourceList";
      this.service.showSpinner();
    this.service.postApi(url,data).subscribe(res => {

       this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.toastr.success('Disease List fetched successfully')
         this.diseaseListArr = res['result']['docs'];
        // this.page = res['result']['page']
        this.diseaseListArrlength=this.diseaseListArr.length

      }
      else if (res['responseCode'] == 404){
          this.toastr.error('No customer found') 
      }
      else{
        this.toastr.error('Something went wrong')
      }
    }, err => {

       this.service.hideSpinner(); 
      
    })
  }

  viewDisease(disease_id) {
    this.router.navigate(['view-disease/'+ disease_id])
  }
  editDisease(disease_id) {
    this.router.navigate(['edit-disease/'+ disease_id])
  }
  newDisease(){
    this.router.navigate(['add-new-disease/'])
  }
  openModal(id){
    this.delete_id=id
    $('#deleteModal').modal('show')
    }

    deleteDisease(){
  
      var url = 'api/v1/admin/deleteResource/'+ this.delete_id;
      this.service.showSpinner();
      this.service.deleteApi(url).subscribe(res=>{
        $('#deleteModal').modal('hide')
        this.service.hideSpinner();
        if(res['responseCode']==200){
        this.toastr.success('Disease removed successfully')
        this.getDiseaseList();
         
        }else {
          this.toastr.error(res['message']);
        }
      },err=>{
       
        this.service.hideSpinner();
        if(err['responseCode']=='401'){
          this.service.onLogout();
          this.toastr.error('Unauthorized Access');
        }else{
        this.toastr.error('Something Went Wrong');
     }
      })
    }
  
}
