import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainserviceService } from 'src/app/mainservice.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.css']
})
export class ViewCustomerComponent implements OnInit {
  custId: any;
  custDetail: any;

  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    private service: MainserviceService,
    private toastr: ToastrService,
    private browseranimation: BrowserAnimationsModule,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    let obj = this.route.params.subscribe(params => {
      this.custId = (params['id']); // (+) converts string 'id' to a number
   
       localStorage.setItem('custId',this.custId)
       });
       this.viewDetail();
  
  }

  viewDetail(){
  
    var url = 'api/v1/admin/viewCustomer/'+ this.custId;
    this.service.showSpinner();
    this.service.get(url).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['responseCode']==200){
       this.custDetail = res['result']
      
       
      }else {
        this.toastr.error(res['message']);
      }
    },err=>{
     
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.toastr.error('Unauthorized Access');
      }else{
      this.toastr.error('Something Went Wrong');
   }
    })
  }

  back(){
    this.router.navigate(['customer-management/'])
  }
}
